<template>
  <div id="container" v-if="fetch_data">
    <section id="mov_archives">
      <div class="wrap">
        <h2 class="main_ttl"><span>全講義検索</span></h2>
        <div class="cts_box">
          <form @submit="update">
            <div class="free_search_box">
              <p>検索キーワード、絞り込み条件を設定して<br class="sp">検索できます。</p>
              <input type="search" class="free_search" v-model="query" placeholder="検索したい内容を入力">
            </div>

            <div class="search_box">
              <h3 class="min_ttl">表示内容の絞り込み</h3>
              <p class="lead_txt">絞り込みたい検索項目をチェックしてください。（複数可）</p>

              <div class="search_favorites flex mb30">
                <h4 class="bold sp_alnC">「お気に入り」の中から検索</h4>
                <div class="toggle_btn flex" v-bind:class="{'on': only_favorites}">
                  <p class="no">しない</p>
                  <div class="toggle_wrap"
                       @click="only_favorites = !only_favorites">
                    <span></span>
                  </div>
                  <p class="yes">する</p>
                </div>
              </div>

              <template v-for="space in fetch_data.spaces">
                <template v-if="space.access_key !== 'contents_archive' && space.rooms.length > 0">
                  <div class="cat" :key="space.id">
                    <h4 class="bold">{{ space.title }}</h4>
                    <div class="alert_search">
                      <div class="checkbox c_red" v-for="room in space.rooms" :key="room.id" v-bind:class="{disabled: !room.readable}">
                        <input v-bind:id="`check${room.id}`" type="checkbox" v-model="room_ids" v-bind:value="room.id" v-bind:disabled="!room.readable">
                        <label v-bind:for="`check${room.id}`">{{ room.title }}</label>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <div>
                <a href="javascript:void(0)" class="blue bold" @click="room_ids=[]">すべてのチェックをはずす</a>
              </div>
              <div class="button_wrap">
                <input type="submit" class="submit light skeleton" value="講義を検索">
              </div>
            </div>
          </form>
        </div>

        <div class="cts_box" id="posts-list">
          <h3 class="min_ttl">講義一覧</h3>
          <p class="lead_txt" v-if="fetch_data.posts.length > 0">これまで複眼経済塾内で公開された講義の一覧です。（ワークショップ等有料講義動画は除きます）</p>
          <p class="lead_txt" v-else>一致する講義は見つかりませんでした。</p>

          <div class="sort flex" v-if="fetch_data.posts.length > 0">
            <p>並び替え</p>
            <select v-model="order" @change="pageChanged(1)">
              <option value="desc">投稿日の新しい順</option>
              <option value="asc">投稿日の古い順</option>
            </select>
          </div>

          <div class="mov_archves_list">

            <div class="mov_archives" v-for="model in fetch_data.posts" :key="model.id">
              <!--  v-bind:class="{disabled: !model.readable}" -->
              <a href="javascript:void(0);" class="flex"
                 @click="show(model)">
                <div class="thumb">
                  <img v-bind:src="`/common/images/contents/${thumbName(model)}`" alt="">
                  <div class="tag watch" v-if="model.played">視聴済み</div>
                  <div class="tag_list">
                    <span class="tag movie" v-show="model.video_file_count">動画</span>
                    <span class="tag pdf" v-show="model.pdf_file_count">PDF</span>
                    <span class="tag mp3" v-show="model.audio_file_count">音声</span>
                  </div>
                </div>
                <div class="info">
                  <div class="room-name">{{ model.room_name }}</div>
                  <div class="title" v-if="model.title">{{ model.title }}</div>
                  <div class="updated_at flex">
                    <p>投稿日：{{ moment(model.published_at, 'YYYY年MM月DD日') }}</p>
                    <div class="favorite flex" v-bind:class="{active: model.favorite}">
                      <img v-bind:src="`/common/images/icon/star_favorite_${model.favorite}.svg`" alt="お気に入り"><p>お気に入り</p>
                    </div>
                  </div>
                  <span class="description" v-html="model.description"></span>
                </div>
              </a>
            </div>
          </div>
          <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler" v-if="infiniteLoadingEnabled">
            <span slot="no-more">これ以上の投稿はありません</span>
          </infinite-loading>
          <!--pager-->
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged" v-if="!infiniteLoadingEnabled"/>
          <!--//pager-->
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  mixins: [Libraries],
  components: {
    Pagination
  },
  data() {
    return {
      fetch_data: null,
      page: this.$route.query.page == null ? 1 : Number(this.$route.query.page),
      query: null,
      room_ids: this.$route.query.room_ids ? this.$route.query.room_ids.split(',') : [],
      order: this.$route.query.order ? this.$route.query.order : 'desc',
      infiniteLoadingEnabled: true,
      hash: this.$route.hash,
      only_favorites: this.$route.query.only_favorites
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    '$route'() {
      this.fetch_data = null
      this.fetchData()
    }
  },
  methods: {
    // 無限スクロール
    infiniteHandler($state) {
      if (this.fetch_data.pagination.last_page) {
        $state.complete()
      } else {
        this.page += 1
        this.fetchData()
      }
    },

    fetchData() {
      if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
        this.scrollTop()
        this.startAjax()
      }
      this.axios
          .get(`${this.env.api_base_url}sns/contents.json`, {
            params: {
              draft: this.$route.query.draft,
              page: this.page,
              query: this.$route.query.query,
              order: this.$route.query.order,
              room_ids: this.room_ids ? this.room_ids.join(',') : null,
              only_favorites: this.only_favorites ? 1 : null
            }
          })
          .then(response => {
            if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
              this.fetch_data = response.data
            } else {
              this.fetch_data.posts = this.fetch_data.posts.concat(response.data.posts)
              this.fetch_data.pagination = response.data.pagination
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.loaded()
            this.finishAjax()

            if (this.hash) {
              setTimeout(() => {
                this.$scrollTo(this.hash, 0, {offset: -140})
                this.hash = null
              }, 300)
            }
          })
    },
    update(event) {
      event.preventDefault()
      this.pageChanged(1)
    },
    pageChanged(page) {
      this.page = page
      const query = {
        page: page,
        query: this.query,
        room_ids: this.room_ids ? this.room_ids.join(',') : null,
        order: this.order,
        only_favorites: this.only_favorites ? 1 : null
      }
      this.$router.push({
        name: this.$route.name,
        query: query
      })
    },
    show(model) {
      this.$router.push({name: 'SnsPost', params: {post_id: model.id}})
    },
    thumbName(model) {
      return `${model.room_access_key}.jpg`
    },
    /*
    favoritePost(post) {
      this.axios
          .post(`${this.env.api_base_url}sns/favorite_post.json`, {
            id: post.id
          })
          .then(() => {
            post.favorite = !post.favorite
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
    */
  }
}
</script>

<style>
a.disabled {
  opacity: 0.5;
  pointer-events: none !important;
  cursor: none !important;
}

</style>